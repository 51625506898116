import * as RadixUISelect from "@radix-ui/react-select";
import React from "react";
import { ChevronDownIcon } from "lucide-react";

import { SelectItem } from "./SelectItem";
import { ZIndex } from "../ZIndex";
import * as MapUtils from "Utils/MapUtils";
import * as StyleUtils from "Utils/StyleUtils";

export function Select<TValue>(props: SelectProps<TValue>): React.ReactElement {
  const itemsByKey: Map<string, SelectItem<TValue>> = new Map(props.items.map((item) => [item.itemKey, item]));
  const itemsByValue: Map<TValue, SelectItem<TValue>> = new Map(props.items.map((item) => [item.value, item]));

  return (
    <RadixUISelect.Root
      value={MapUtils.getOrThrow(itemsByValue, props.value).itemKey}
      onValueChange={(selectedKey) => props.onChange(MapUtils.getOrThrow(itemsByKey, selectedKey).value)}
    >
      <RadixUISelect.Trigger
        className={StyleUtils.mergeClassNames(
          "inline-flex items-center justify-between rounded px-1 text-[13px] leading-none gap-[5px] bg-transparent text-zinc-300 border-none",
          props.className,
        )}
        aria-label={props.ariaLabel}
      >
        <RadixUISelect.Value placeholder={props.placeholder} />
        <RadixUISelect.Icon>
          <ChevronDownIcon className="text-zinc-600 h-4 w-4" />
        </RadixUISelect.Icon>
      </RadixUISelect.Trigger>
      <RadixUISelect.Portal>
        <RadixUISelect.Content
          className="overflow-hidden bg-zinc-900 text-white rounded-md border-[1px] border-zinc-700/80 outline outline-[1px] outline-black w-[var(--radix-select-trigger-width)]"
          position="popper"
          style={{ zIndex: props.zIndex }}
        >
          <RadixUISelect.Viewport>
            {props.items.map((item) => (
              <RadixUISelect.Item
                className={StyleUtils.mergeClassNames(
                  "text-[13px] px-1 leading-none rounded-[3px] flex items-center h-[25px] relative overflow-x-hidden text-nowrap select-none data-[disabled]:pointer-events-none",
                  "hover:bg-zinc-700",
                )}
                key={item.itemKey}
                title={typeof item.displayElement === "string" ? item.displayElement : undefined}
                value={item.itemKey}
              >
                <RadixUISelect.ItemText>{item.displayElement}</RadixUISelect.ItemText>
              </RadixUISelect.Item>
            ))}
          </RadixUISelect.Viewport>
        </RadixUISelect.Content>
      </RadixUISelect.Portal>
    </RadixUISelect.Root>
  );
}

export interface SelectProps<TValue> {
  ariaLabel: string;
  className?: string;
  items: SelectItem<TValue>[];
  placeholder?: string;
  value: TValue;
  zIndex?: ZIndex;

  onChange: (selectedValue: TValue) => void;
}
