import * as React from "react";

import * as Api from "ApiContracts/control/api/api";
import * as ApiUtils from "ApiUtils";
import * as CustomHooks from "CustomHooks";
import { Overlay } from "Overlay";
import { SetupInstructions } from "SetupInstructions";
import * as pubsub from "ApiContracts/subtrace/pubsub/pubsub";

export function LivePage(props: LivePageProps): React.ReactNode {
  const [url, setUrl] = React.useState<string | null>(null);

  const { currentNamespace } = CustomHooks.useNamespaceManager();
  const { orgId } = CustomHooks.useCurrentOrg();
  const { namespaceId } = currentNamespace;
  const { setupStatus, onSetupStatusChange } = props;

  React.useEffect(() => {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function populateSetupStatus(): Promise<void> {
      if (setupStatus != null) {
        return;
      }

      const request: Api.HasSeenPublisher_Request = { orgId };
      try {
        const response: Response = await ApiUtils.post("/api/HasSeenPublisher", request, { signal });
        await ApiUtils.assertStatus(response, 200);
        const { hasSeenPublisher }: Api.HasSeenPublisher_Response = await response.json();

        if (!hasSeenPublisher) {
          const request: Api.GenerateNamespaceToken_Request = { namespaceId };
          const response: Response = await ApiUtils.post("/api/GenerateNamespaceToken", request, { signal });
          await ApiUtils.assertStatus(response, 200);
          const { token }: Api.GenerateNamespaceToken_Response = await response.json();

          onSetupStatusChange({ isComplete: false, namespaceToken: token });
        } else {
          onSetupStatusChange({ isComplete: true });
        }
      } catch (error: unknown) {
        if (signal.aborted) {
          // Do nothing, the API call was canceled
        } else {
          throw error;
        }
      }
    }

    populateSetupStatus();

    return (): void => {
      controller.abort("Cleaning up effect populateSetupStatus");
    };
  }, [namespaceId, onSetupStatusChange, orgId, setupStatus]);

  React.useEffect(() => {
    if (url) {
      return;
    }

    (async (): Promise<void> => {
      const body = JSON.stringify(pubsub.JoinSubscriber_Request.toJSON({ namespaceId }));
      const resp = await fetch(`/api/JoinSubscriber`, { method: "POST", body });
      const json = await resp.json();
      const { websocketUrl } = pubsub.JoinSubscriber_Response.fromJSON(json);
      setUrl(websocketUrl);
    })();
  });

  if (setupStatus == null) {
    return null;
  }

  if (!url) {
    return <React.Fragment />;
  }

  return (
    <div className="m-2 flex w-full relative rounded-md">
      {setupStatus.isComplete ? null : (
        <React.Fragment>
          <div className="flex absolute h-full w-full z-20 justify-center items-center self-center rounded-md">
            {setupStatus.namespaceToken != null ? (
              <SetupInstructions namespaceToken={setupStatus.namespaceToken} onClose={() => onSetupStatusChange({ isComplete: true })} orgId={orgId} />
            ) : null}
          </div>
          <Overlay className="absolute w-full h-full backdrop-blur-sm rounded" />
        </React.Fragment>
      )}
      <iframe sandbox="allow-scripts allow-same-origin allow-downloads" allow="clipboard-write" className="rounded h-full w-full" src={`/iframer?url=${encodeURIComponent(url)}`} />
    </div>
  );
}

export type LivePageSetupStatus =
  | {
      isComplete: true;
    }
  | {
      isComplete: false;
      namespaceToken: string;
    };

export interface LivePageProps {
  setupStatus: LivePageSetupStatus | undefined;

  onSetupStatusChange: (setupStatus: LivePageSetupStatus) => void;
}
