import * as React from "react";
import { CheckIcon, InfoIcon, GithubIcon, StarIcon } from "lucide-react";

import * as ArrayUtils from "Utils/ArrayUtils";
import * as CustomHooks from "CustomHooks";
import * as StyleUtils from "Utils/StyleUtils";
import * as Verify from "Utils/Verify";
import AWSLogo from "../images/aws_logo.svg";
import AzureLogo from "../images/azure_logo.svg";
import DashboardScreenshot from "../images/DashboardScreenshot.png";
import DockerLogo from "../images/docker_logo.svg";
import GCPLogo from "../images/gcp_logo.svg";
import GoLogo from "../images/go_logo.svg";
import JavaLogo from "../images/java_logo.svg";
import KubernetesLogo from "../images/kubenetes_logo.svg";
import NickProfilePicture from "../images/NickProfilePicture.jpg";
import NodejsLogo from "../images/nodejs_logo.svg";
import PhpLogo from "../images/php_logo.svg";
import RubyLogo from "../images/ruby_logo.svg";
import RustLogo from "../images/rust_logo.svg";
import PythonLogo from "../images/python_logo.svg";
import YCLogo from "../images/yc_logo.svg";
import XLogo from "../images/x_logo.svg";
import { ExternalLink } from "DesignComponents/ExternalLink";
import { InternalLink } from "DesignComponents/InternalLink";
import { User } from "User";

function Orbit(props: { children: React.ReactElement[]; radius: number; numberOfItems: number }): React.ReactElement | null {
  const size = props.children.length ?? 1;
  const { numberOfItems } = props;

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full">
      <div
        className={StyleUtils.mergeClassNames(
          "pointer-events-auto relative rounded-full border border-dashed border-zinc-700",
          props.radius > 300 ? "animate-spin-slow-2" : "animate-spin-slow-1",
          props.radius > 300 ? "rotate-90" : "",
        )}
        style={{
          top: `calc(120% - ${props.radius}px)`,
          left: `calc(50% - ${props.radius}px)`,
          width: `${2 * props.radius}px`,
          height: `${2 * props.radius}px`,
        }}
      >
        {ArrayUtils.extendByRepetition(props.children, numberOfItems).map((_element, i) => (
          <div
            key={i}
            className="absolute"
            style={{
              transform: `rotate(${90 - (i * 360) / numberOfItems}deg)`,
              left: `calc(50% - 16px + ${props.radius * Math.cos((i * 2 * Math.PI) / numberOfItems)}px)`,
              top: `calc(50% - 16px - ${props.radius * Math.sin((i * 2 * Math.PI) / numberOfItems)}px)`,
            }}
          >
            {props.children[i % size]}
          </div>
        ))}
      </div>
    </div>
  );
}

function Integrations(): React.ReactElement {
  function Logo(props: { name: string; src: string }): React.ReactElement {
    return (
      <div className="w-7 h-7 group relative">
        <div className="absolute w-full h-full flex justify-center items-center">
          <img className="absolute w-full transition ease-in-out hover:brightness-[1.20]" src={props.src} alt={props.name} />
        </div>
        <span className="whitespace-pre hidden group-hover:block select-none absolute left-[50%] translate-x-[-50%] -top-7 text-center text-[10px] font-medium leading-[10px] bg-zinc-800 text-zinc-400 px-2 py-1 rounded-sm border border-zinc-700">
          {props.name}
        </span>
      </div>
    );
  }

  return (
    <div className="overflow-hidden absolute left-0 top-0 w-full h-full">
      <div className="relative top-0 left-0 w-full h-full">
        <div
          className="absolute top-[calc(100%-8rem)] left-[calc(50%-12rem)] w-[24rem] h-[16rem]"
          style={{
            background: "radial-gradient(#07598560 0%, #07598540 25%, transparent 50%, transparent 100%)",
          }}
        />
        <Orbit radius={360} numberOfItems={16}>
          <Logo name="Node.js" src={NodejsLogo} />
          <Logo name="Go" src={GoLogo} />
          <Logo name="Ruby" src={RubyLogo} />
          <Logo name="Java" src={JavaLogo} />
          <Logo name="PHP" src={PhpLogo} />
          <Logo name="Rust" src={RustLogo} />
          <Logo name="Python" src={PythonLogo} />
        </Orbit>
        <Orbit radius={240} numberOfItems={12}>
          <Logo name="Docker" src={DockerLogo} />
          <Logo name="Kubernetes" src={KubernetesLogo} />
          <Logo name="AWS" src={AWSLogo} />
          <Logo name="Azure" src={AzureLogo} />
          <Logo name="Google Cloud" src={GCPLogo} />
        </Orbit>
      </div>
    </div>
  );
}

export function LandingPage(): React.ReactElement {
  const currentUser: User | undefined = CustomHooks.useCurrentUser();
  const [numberOfGithubStars, setNumberOfGithubStars] = React.useState<number | undefined>(undefined);

  React.useEffect(function getNumberOfGithubStars() {
    const controller: AbortController = new AbortController();
    const { signal } = controller;

    async function getAndSetNumberOfGithubStars(): Promise<void> {
      try {
        const response: Response = await fetch("https://api.github.com/repos/subtrace/subtrace", { signal });
        const foo = await response.json();

        if (!signal.aborted) {
          setNumberOfGithubStars(foo.stargazers_count);
        }
      } catch {
        if (signal.aborted) {
          // Do nothing, the API call was canceled
        }
      }
    }

    getAndSetNumberOfGithubStars();

    return (): void => {
      controller.abort("Cleaning up hook getNumberOfGithubStars");
    };
  }, []);

  return (
    <div className="w-full h-vh flex flex-col px-4 lg:px-0">
      <div className="flex justify-center m-8">
        <div className="flex items-align justify-between w-full max-w-6xl">
          <div className="my-auto flex items-center space-x-4">
            <InternalLink className="px-4 py-2 mr-4 text-md tracking-widest font-mono text-white" label="subtrace" target="/" />
            <ExternalLink className="px-4 py-2 text-sm hidden lg:block" label="Pricing" target="#pricing" />
            <ExternalLink className="px-4 py-2 text-sm hidden lg:block" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="px-4 py-2 text-sm hidden lg:block" label="Discord" openInNewTab target="https://subtrace.dev/discord" />
          </div>
          {renderTopRightButtons()}
        </div>
      </div>

      <div className="mt-8 flex justify-center">
        <div className="flex justify-center space-x-3">
          <img src={YCLogo} className="w-4 h-4" alt="Backed by Y Combinator" />
          <span className="text-[11px] text-zinc-400 font-medium">Backed by Y Combinator</span>
        </div>
      </div>

      <div className="mt-8 flex justify-center">
        <h2 className="font-semibold whitespace-pre text-3xl sm:text-4xl md:text-5xl space-y-1 lg:space-y-2 w-full text-center lg:max-w-xl flex flex-col items-center px-8 lg:px-4 text-zinc-100 leading-[1.30]">
          <span>Connect your backend</span>
          <span>to Chrome DevTools</span>
        </h2>
      </div>

      <div className="mt-8 flex justify-center">
        <div className="text-zinc-200 max-w-[28rem] text-center">Subtrace gives you a livestream of API requests so that you can inspect headers, payload, latency, and more.</div>
      </div>

      <div className="mt-16 flex items-center flex flex-col space-y-4">
        <a href="/login" className="text-sm text-zinc-200 font-medium rounded bg-sky-700/95 hover:bg-sky-600 group px-8 py-4 w-full max-w-[24rem] text-[16px] text-center">
          <span>Try for free</span>
          <span className="inline-block ml-2 transition ease-in-out translate-x-[-1px] group-hover:translate-x-[+1px]">&rarr;</span>
        </a>
      </div>

      <div className="mt-24 flex justify-center md:px-4">
        <img src={DashboardScreenshot} className="w-full max-w-4xl" />
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-4 md:space-y-8 max-w-[100%]">
          <div className="flex space-x-8 items-center text-xl font-medium">
            <div className="relative overflow-hidden w-full h-96 px-8 py-6 bg-zinc-900/70 rounded-xl">
              <span className="text-zinc-300">Works everywhere.</span>
              <span> </span>
              <span className="text-zinc-400">Any language. Any cloud provider.</span>
              <Integrations />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 space-y-4 md:space-y-0 md:space-x-4 items-center text-lg font-medium">
            <div className="relative overflow-hidden w-[calc(100vw-32px)] h-[calc(100vw-32px)] md:w-96 md:h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Chrome DevTools for full stack.</span>
              <span> </span>
              <span className="text-zinc-400/90">Use the interface you already know and love for both frontend and backend development.</span>
            </div>
            <div className="relative overflow-hidden w-[calc(100vw-32px)] h-[calc(100vw-32px)] md:w-96 md:h-96 px-8 py-6 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="text-zinc-300">Easy to integrate.</span>
              <span> </span>
              <span className="text-zinc-400/90">Subtrace requires no code changes in your app to integrate. All it takes is one command.</span>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-48 self-center justify-center px-4 hidden md:block">
        <div className="w-full max-w-4xl flex flex-col">
          <div className="text-zinc-300 font-medium text-xl text-center">Integrate with just one command.</div>
          <div className="mt-6">{renderDiffView()}</div>
        </div>
      </div>

      <div className="mt-48 flex justify-center">
        <a
          className="w-full hover:bg-zinc-900/25 border border-zinc-900 hover:border-zinc-800 px-6 py-6 rounded-md max-w-lg flex flex-col space-y-6"
          href="https://x.com/skeptrune/status/1879072724796244194"
          target="_blank"
          rel="noreferrer"
        >
          <div className="text-zinc-300 text-sm flex flex-col space-y-2">
            <p>
              I never want to build anything without
              <span> </span>
              <a target="_blank" className="hover:brightness-[1.20]" href="https://x.com/subtrace_dev" rel="noreferrer">
                @subtrace_dev
              </a>
              <span> </span>
              configured locally ever again. Life changing piece of software.
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex justify-center item-center space-x-2 text-xs">
              <img className="mx-[2px] w-4 h-4 rounded-full" src={NickProfilePicture} />
              <a href="https://x.com/skeptrune" target="_blank" className="text-zinc-300 hover:brightness-[1.2] font-semibold" rel="noreferrer">
                Nick Khami
              </a>
              <a href="https://trieve.ai" target="_blank" className="text-zinc-500 hover:brightness-[1.2] font-medium" rel="noreferrer">
                Founder, Trieve (YC W24)
              </a>
            </div>
            <img className="h-3 w-3 invert opacity-[0.50]" src={XLogo} />
          </div>
        </a>
      </div>

      <div id="pricing" className="mt-48 flex items-center justify-center">
        <Pricing />
      </div>

      <div className="mt-48 flex justify-center">
        <div className="flex flex-col space-y-4 md:space-y-8 max-w-[100%]">
          <div className="items-center text-sm grid grid-cols-2 md:grid-cols-4 md:space-x-8">
            <div className="relative mx-auto md:mx-0 my-[calc(5vw-16px)] md:my-0 overflow-hidden w-[45vw] h-[45vw] md:w-44 md:h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Built on ClickHouse.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Search through your requests using the fastest database for analytics.</span>
            </div>
            <div className="relative mx-auto md:mx-0 my-[calc(5vw-16px)] md:my-0 overflow-hidden w-[45vw] h-[45vw] md:w-44 md:h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">On-prem storage.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Keep your requests in your own servers for security and data compliance.</span>
            </div>
            <div className="relative mx-auto md:mx-0 my-[calc(5vw-16px)] md:my-0 overflow-hidden w-[45vw] h-[45vw] md:w-44 md:h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Simple pricing.</span>
              <span> </span>
              <span className="font-normal text-zinc-300/80">Only pay for the requests you trace with strict spend limits.</span>
            </div>
            <div className="relative mx-auto md:mx-0 my-[calc(5vw-16px)] md:my-0 overflow-hidden w-[45vw] h-[45vw] md:w-44 md:h-44 p-5 bg-zinc-900/70 hover:bg-zinc-900 rounded-xl">
              <span className="font-medium text-zinc-200">Open source.</span>
              <span> </span>
              <ExternalLink className="font-normal text-zinc-300/95" label="Star us on GitHub." target="https://github.com/subtrace/subtrace" />
            </div>
          </div>

          <div className="w-full h-24 flex px-6 py-3 bg-zinc-900 rounded-xl">
            <div className="w-full flex flex-row items-center justify-between">
              <div className="text-xl font-medium flex items-center text-zinc-200">
                <span className="text-zinc-200">Interested?</span>&nbsp;
                <span className="text-zinc-500 hidden md:block">Try for free.</span>
              </div>
              <InternalLink className="px-4 py-2" label="Get started" target="/login" showAsButton showArrow />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-20 flex justify-center">
        <div className="w-full max-w-3xl py-2 mt-16 mb-32">
          <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row justify-center md:justify-begin md:items-center md:space-x-8">
            <span className="text-[13px] pointer-events-none tracking-wide rounded font-semibold text-zinc-600 font-mono mr-4">subtrace</span>
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Docs" target="https://docs.subtrace.dev" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Discord" openInNewTab target="https://subtrace.dev/discord" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="GitHub" openInNewTab target="https://github.com/subtrace/subtrace" />
            <ExternalLink className="text-zinc-500/95 hover:text-zinc-500" label="Support" target="mailto:support@subtrace.dev" />
          </div>
        </div>
      </div>
    </div>
  );

  function renderDiffSummary(): React.ReactNode {
    return (
      <div className="flex flex-row items-center space-x-1">
        <span className="text-[#3fb950] font-semibold">+2</span>
        <span className="text-[#f85149] font-semibold">-1</span>
        <div className="flex flex-row space-x-px">
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[#238636] border rounded-sm border-[#238636]" />
          <div className="h-2 w-2 bg-[repeating-linear-gradient(to_right_bottom,_#fff9,_#fff9_.11rem,_#da3633_.11rem,_#da3633_.22rem)] bg-[#da3633] border rounded-sm border-[#da3633]" />
          <div className="h-2 w-2 bg-[#656c7633] border rounded-sm border-[#3d444d]" />
        </div>
      </div>
    );
  }

  function renderDiffView(): React.ReactNode {
    return (
      <div className="outline outline-[1px] outline-[#3d444d] rounded-md flex flex-col relative">
        <div className="w-full h-10 bg-[#151b23] border-[#3d444d] border-b flex flex-row justify-between text-white text-xs font-mono items-center rounded-t-md px-3">
          <span>Dockerfile</span>
          {renderDiffSummary()}
        </div>
        <div className="w-full h-full grid grid-cols-2 font-mono bg-[#0d1117]">
          <div className="border-[#3d444d] border-r h-full flex flex-col">
            {renderDiffViewLeftSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
          <div className="h-full flex flex-col">
            {renderDiffViewRightSide()}
            <div className="flex-grow bg-[#151b23] rounded-b-md" />
          </div>
        </div>
      </div>
    );
  }

  function renderDiffViewLeftSide(): React.ReactNode {
    return (
      <table className="whitespace-pre overflow-hidden text-[11px] w-full">
        <tbody>
          {renderDiffRow(
            1,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            2,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">WORKDIR</span>
              <span> /app</span>
            </span>,
          )}
          {renderDiffRow(
            3,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> .</span>
              <span> .</span>
            </span>,
          )}
          {renderDiffRow(
            4,
            DiffLineKind.Removed,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;python&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;main.py&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffViewRightSide(): React.ReactNode {
    return (
      <table className="whitespace-pre overflow-hidden text-[11px] w-full table-fixed">
        <tbody>
          {renderDiffRow(
            1,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">FROM</span>
              <span> debian:12</span>
            </span>,
          )}
          {renderDiffRow(
            2,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">WORKDIR</span>
              <span> /app</span>
            </span>,
          )}
          {renderDiffRow(
            3,
            DiffLineKind.Unchanged,
            <span>
              <span className="text-[#ff7b72]">COPY</span>
              <span> .</span>
              <span> .</span>
            </span>,
          )}
          {renderDiffRow(
            4,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">RUN</span>
              <span> curl -fsSLO https://subtrace.dev/download/latest/linux/amd64/subtrace &amp;&amp; chmod +x ./subtrace</span>
            </span>,
          )}
          {renderDiffRow(
            5,
            DiffLineKind.Added,
            <span>
              <span className="text-[#ff7b72]">CMD</span>
              <span> &#091;</span>
              <span className="text-[#a5d6ff]">&quot;./subtrace&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;run&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;--&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;python&quot;</span>
              <span>, </span>
              <span className="text-[#a5d6ff]">&quot;main.py&quot;</span>
              <span>&#093;</span>
            </span>,
          )}
        </tbody>
      </table>
    );
  }

  function renderDiffRow(lineNumber: number, diffLineKind: DiffLineKind, text: React.ReactNode): React.ReactNode {
    let diffColumnText: string;
    let lineNumberBackgroundColor: string, diffBackgroundColor: string, lineNumberTextColor: string;

    if (diffLineKind === DiffLineKind.Added) {
      diffColumnText = "+";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#3fb9504d]";
      diffBackgroundColor = "bg-[#2ea04326]";
    } else if (diffLineKind === DiffLineKind.Removed) {
      diffColumnText = "-";
      lineNumberTextColor = "text-white";
      lineNumberBackgroundColor = "bg-[#f851494d]";
      diffBackgroundColor = "bg-[#f8514926]";
    } else if (diffLineKind === DiffLineKind.Unchanged) {
      diffColumnText = "";
      lineNumberTextColor = "text-[#9198a1]";
      lineNumberBackgroundColor = "";
      diffBackgroundColor = "";
    } else {
      Verify.isNever(diffLineKind);
    }

    return (
      <tr className="h-6">
        <td className={`${lineNumberTextColor} text-center w-10 p-0 ${lineNumberBackgroundColor} select-none`}>{lineNumber}</td>
        <td className={`text-white text-center w-6 p-0 ${diffBackgroundColor} select-none`}>{diffColumnText}</td>
        <td className={`text-white ${diffBackgroundColor}`}>{text}</td>
      </tr>
    );
  }

  function renderTopRightButtons(): React.ReactElement | null {
    return (
      <div className="float-right my-auto flex items-center space-x-4">
        {currentUser?.isLoggedIn ? (
          <React.Fragment>
            {renderGithubStars()}
            <ExternalLink className="px-4 py-2 hidden md:block" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" />
            <InternalLink className="px-4 py-2" label="Dashboard" showArrow showAsButton target="/dashboard" />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {renderGithubStars()}
            <ExternalLink className="px-4 py-2 hidden md:block" label="Schedule demo" openInNewTab target="https://cal.com/subtrace/demo" />
            <InternalLink className="px-4 py-2" label="Try for free" target="/login" showArrow showAsButton />
          </React.Fragment>
        )}
      </div>
    );
  }

  function renderGithubStars(): React.ReactElement {
    return (
      <a
        target="_blank"
        rel="noreferrer"
        href="https://github.com/subtrace/subtrace"
        className="justify-center items-center space-x-2 rounded hover:bg-zinc-900/50 px-3 py-[6px] border border-zinc-900 hover:border-zinc-800 text-zinc-300 hidden lg:flex"
      >
        <GithubIcon className="mr-1" size={14} />
        <span className="text-xs font-medium">Star us on GitHub!</span>
        {numberOfGithubStars != null ? <span className="px-2 py-[1px] text-[11px] rounded-full bg-zinc-700 font-medium">{numberOfGithubStars}</span> : <StarIcon size={15} />}
      </a>
    );
  }
}

function Pricing(): React.ReactNode {
  const [minRequests, freeTier, scaleTier, maxRequests] = [0, 10, 100, 250];
  const [requests, setRequests] = React.useState<number>(50);

  const percent = (100 * (requests - minRequests)) / (maxRequests - minRequests);

  let retention = -1,
    users = -1,
    cost = -1;
  if (requests < freeTier) {
    retention = 7;
    users = 3;
    cost = 0;
  } else if (requests < scaleTier) {
    retention = 30;
    users = 10;
    cost = requests * 1.0;
  } else if (requests < maxRequests) {
    retention = 30;
    users = 10;
    cost = scaleTier * 1.0 + (requests - scaleTier) * 0.5;
  }

  return (
    <div className="flex flex-col w-full max-w-[30rem] space-y-12">
      <div className="text-center font-semibold text-lg text-zinc-300 py-4">Pricing that's designed for startups</div>
      <div className="flex justify-center items-center relative">
        <input
          type="range"
          className="pricing grow-1 shrink-0 w-full"
          min={minRequests}
          max={maxRequests}
          value={requests}
          onChange={(ev) => setRequests(parseInt(ev.target.value))}
          style={{
            background: `linear-gradient(to right, #0369a1 0%, #0369a1 ${percent}%, #27272a ${percent}%, #27272a 100%)`,
          }}
        />
        <div
          className="whitespace-pre text-[11px] leading-[11px] font-medium text-zinc-200 absolute w-fit text-center bg-sky-800 px-2 py-1 top-[-36px] translate-x-[-50%] rounded"
          style={{ left: `calc(${percent}% - 10px + ${(20 * (100 - percent)) / 100}px)` }}
        >
          {requests < maxRequests ? `${requests}M` : `${requests}M+`} requests
        </div>
      </div>
      <div className="flex flex-row md:space-x-4">
        <div className="w-[50%] text-sm text-zinc-300 flex flex-col space-y-[6px]">
          <span className="flex flex-row space-x-2 items-center">
            <CheckIcon size={12} />
            <span>{requests < maxRequests ? `${requests}M` : <>&infin;</>} requests</span>
            {requests < maxRequests ? (
              <div className="relative group">
                <InfoIcon className="text-zinc-400" size={14} />
                <span className="hidden group-hover:block absolute bottom-[-2rem] right-0 translate-x-[50%] whitespace-pre px-2 py-1 bg-zinc-800 rounded text-xs border border-1 border-zinc-700">
                  resets monthly
                </span>
              </div>
            ) : null}
          </span>

          <span className="flex flex-row space-x-2 items-center">
            <CheckIcon size={12} />
            <span>{retention > 0 ? `${retention} days` : <>&infin;</>} retention</span>
          </span>

          <span className="flex flex-row space-x-2 items-center">
            <CheckIcon size={12} />
            <span>{users > 0 ? users : <>&infin;</>} team members</span>
          </span>

          <span className="flex flex-row space-x-2 items-center">
            <CheckIcon size={12} />
            <span>SSO &amp; SAML</span>
          </span>

          {requests >= freeTier ? (
            <span className="flex flex-row space-x-2 items-center">
              <CheckIcon size={12} />
              <span>Onboarding support</span>
            </span>
          ) : (
            <span className="flex flex-row">
              <span>&nbsp;</span>
            </span>
          )}

          {requests === maxRequests ? (
            <span className="flex flex-row space-x-2 items-center">
              <CheckIcon size={12} />
              <span>Custom contracts</span>
            </span>
          ) : (
            <span className="flex flex-row">
              <span>&nbsp;</span>
            </span>
          )}
        </div>

        <div className="w-[50%] flex flex-col space-y-4 items-center">
          <div className="flex flex-col">
            <span className="text-2xl text-center font-semibold text-zinc-300">{requests < maxRequests ? `${"$"}${cost.toFixed(0)}` : "Custom"}</span>
            <span className="text-xs text-center text-zinc-400">{requests < maxRequests ? "per month" : ""}</span>
          </div>
          {requests < maxRequests ? (
            <InternalLink className="text-center py-2 px-4 text-nowrap" label="Get started" showAsButton showArrow target="/login" />
          ) : (
            <ExternalLink className="text-center py-2 px-4 text-nowrap" label="Talk to the founders" showAsButton showArrow target="mailto:founders@subtrace.dev" />
          )}
        </div>
      </div>
    </div>
  );
}

const enum DiffLineKind {
  Added = "Added",
  Removed = "Removed",
  Unchanged = "Unchanged",
}
