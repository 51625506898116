// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.179.0
//   protoc               v3.21.12
// source: subtrace/pubsub/pubsub.proto

/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { FileDescriptorProto } from "ts-proto-descriptors";

export const protobufPackage = "subtrace.pubsub";

/** POST /api/JoinPublisher */
export interface JoinPublisher {
}

export interface JoinPublisher_Request {
}

export interface JoinPublisher_Response {
  error?: string | undefined;
  websocketUrl: string;
}

/** POST /api/JoinSubscriber */
export interface JoinSubscriber {
}

export interface JoinSubscriber_Request {
  namespaceId?: string | undefined;
}

export interface JoinSubscriber_Response {
  error?: string | undefined;
  websocketUrl: string;
}

export interface Event {
  concreteV1?: Event_V1 | undefined;
}

export interface Event_V1 {
  tags: { [key: string]: string };
  harEntryJson: Uint8Array;
}

export interface Event_V1_TagsEntry {
  key: string;
  value: string;
}

export interface SetSubscriberConfig {
  concreteV1?: SetSubscriberConfig_V1 | undefined;
}

export interface SetSubscriberConfig_V1 {
  call?: SetSubscriberConfig_V1_Call | undefined;
  result?: SetSubscriberConfig_V1_Result | undefined;
}

export interface SetSubscriberConfig_V1_Call {
  revision: bigint;
  filters: string[];
}

export interface SetSubscriberConfig_V1_Result {
  revision: bigint;
  error?: string | undefined;
}

export interface AnnounceStats {
  concreteV1?: AnnounceStats_V1 | undefined;
}

export interface AnnounceStats_V1 {
  numPublishers: bigint;
  numSubscribers: bigint;
}

export interface Message {
  concreteV1?: Message_V1 | undefined;
}

export interface Message_V1 {
  event?: Event | undefined;
  setSubscriberConfig?: SetSubscriberConfig | undefined;
  announceStats?: AnnounceStats | undefined;
}

function createBaseJoinPublisher(): JoinPublisher {
  return {};
}

export const JoinPublisher = {
  encode(_: JoinPublisher, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinPublisher {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinPublisher();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JoinPublisher {
    return {};
  },

  toJSON(_: JoinPublisher): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinPublisher>, I>>(base?: I): JoinPublisher {
    return JoinPublisher.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinPublisher>, I>>(_: I): JoinPublisher {
    const message = createBaseJoinPublisher();
    return message;
  },
};

function createBaseJoinPublisher_Request(): JoinPublisher_Request {
  return {};
}

export const JoinPublisher_Request = {
  encode(_: JoinPublisher_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinPublisher_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinPublisher_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JoinPublisher_Request {
    return {};
  },

  toJSON(_: JoinPublisher_Request): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinPublisher_Request>, I>>(base?: I): JoinPublisher_Request {
    return JoinPublisher_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinPublisher_Request>, I>>(_: I): JoinPublisher_Request {
    const message = createBaseJoinPublisher_Request();
    return message;
  },
};

function createBaseJoinPublisher_Response(): JoinPublisher_Response {
  return { error: undefined, websocketUrl: "" };
}

export const JoinPublisher_Response = {
  encode(message: JoinPublisher_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== undefined) {
      writer.uint32(8002).string(message.error);
    }
    if (message.websocketUrl !== "") {
      writer.uint32(10).string(message.websocketUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinPublisher_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinPublisher_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          if (tag !== 8002) {
            break;
          }

          message.error = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.websocketUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinPublisher_Response {
    return {
      error: isSet(object.error) ? globalThis.String(object.error) : undefined,
      websocketUrl: isSet(object.websocketUrl) ? globalThis.String(object.websocketUrl) : "",
    };
  },

  toJSON(message: JoinPublisher_Response): unknown {
    const obj: any = {};
    if (message.error !== undefined) {
      obj.error = message.error;
    }
    if (message.websocketUrl !== "") {
      obj.websocketUrl = message.websocketUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinPublisher_Response>, I>>(base?: I): JoinPublisher_Response {
    return JoinPublisher_Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinPublisher_Response>, I>>(object: I): JoinPublisher_Response {
    const message = createBaseJoinPublisher_Response();
    message.error = object.error ?? undefined;
    message.websocketUrl = object.websocketUrl ?? "";
    return message;
  },
};

function createBaseJoinSubscriber(): JoinSubscriber {
  return {};
}

export const JoinSubscriber = {
  encode(_: JoinSubscriber, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinSubscriber {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinSubscriber();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(_: any): JoinSubscriber {
    return {};
  },

  toJSON(_: JoinSubscriber): unknown {
    const obj: any = {};
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinSubscriber>, I>>(base?: I): JoinSubscriber {
    return JoinSubscriber.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinSubscriber>, I>>(_: I): JoinSubscriber {
    const message = createBaseJoinSubscriber();
    return message;
  },
};

function createBaseJoinSubscriber_Request(): JoinSubscriber_Request {
  return { namespaceId: undefined };
}

export const JoinSubscriber_Request = {
  encode(message: JoinSubscriber_Request, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.namespaceId !== undefined) {
      writer.uint32(10).string(message.namespaceId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinSubscriber_Request {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinSubscriber_Request();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.namespaceId = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinSubscriber_Request {
    return { namespaceId: isSet(object.namespaceId) ? globalThis.String(object.namespaceId) : undefined };
  },

  toJSON(message: JoinSubscriber_Request): unknown {
    const obj: any = {};
    if (message.namespaceId !== undefined) {
      obj.namespaceId = message.namespaceId;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinSubscriber_Request>, I>>(base?: I): JoinSubscriber_Request {
    return JoinSubscriber_Request.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinSubscriber_Request>, I>>(object: I): JoinSubscriber_Request {
    const message = createBaseJoinSubscriber_Request();
    message.namespaceId = object.namespaceId ?? undefined;
    return message;
  },
};

function createBaseJoinSubscriber_Response(): JoinSubscriber_Response {
  return { error: undefined, websocketUrl: "" };
}

export const JoinSubscriber_Response = {
  encode(message: JoinSubscriber_Response, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.error !== undefined) {
      writer.uint32(8002).string(message.error);
    }
    if (message.websocketUrl !== "") {
      writer.uint32(10).string(message.websocketUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): JoinSubscriber_Response {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseJoinSubscriber_Response();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1000:
          if (tag !== 8002) {
            break;
          }

          message.error = reader.string();
          continue;
        case 1:
          if (tag !== 10) {
            break;
          }

          message.websocketUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): JoinSubscriber_Response {
    return {
      error: isSet(object.error) ? globalThis.String(object.error) : undefined,
      websocketUrl: isSet(object.websocketUrl) ? globalThis.String(object.websocketUrl) : "",
    };
  },

  toJSON(message: JoinSubscriber_Response): unknown {
    const obj: any = {};
    if (message.error !== undefined) {
      obj.error = message.error;
    }
    if (message.websocketUrl !== "") {
      obj.websocketUrl = message.websocketUrl;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<JoinSubscriber_Response>, I>>(base?: I): JoinSubscriber_Response {
    return JoinSubscriber_Response.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<JoinSubscriber_Response>, I>>(object: I): JoinSubscriber_Response {
    const message = createBaseJoinSubscriber_Response();
    message.error = object.error ?? undefined;
    message.websocketUrl = object.websocketUrl ?? "";
    return message;
  },
};

function createBaseEvent(): Event {
  return { concreteV1: undefined };
}

export const Event = {
  encode(message: Event, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.concreteV1 !== undefined) {
      Event_V1.encode(message.concreteV1, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.concreteV1 = Event_V1.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Event {
    return { concreteV1: isSet(object.concreteV1) ? Event_V1.fromJSON(object.concreteV1) : undefined };
  },

  toJSON(message: Event): unknown {
    const obj: any = {};
    if (message.concreteV1 !== undefined) {
      obj.concreteV1 = Event_V1.toJSON(message.concreteV1);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Event>, I>>(base?: I): Event {
    return Event.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Event>, I>>(object: I): Event {
    const message = createBaseEvent();
    message.concreteV1 = (object.concreteV1 !== undefined && object.concreteV1 !== null)
      ? Event_V1.fromPartial(object.concreteV1)
      : undefined;
    return message;
  },
};

function createBaseEvent_V1(): Event_V1 {
  return { tags: {}, harEntryJson: new Uint8Array(0) };
}

export const Event_V1 = {
  encode(message: Event_V1, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    Object.entries(message.tags).forEach(([key, value]) => {
      Event_V1_TagsEntry.encode({ key: key as any, value }, writer.uint32(10).fork()).ldelim();
    });
    if (message.harEntryJson.length !== 0) {
      writer.uint32(18).bytes(message.harEntryJson);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_V1 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_V1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          const entry1 = Event_V1_TagsEntry.decode(reader, reader.uint32());
          if (entry1.value !== undefined) {
            message.tags[entry1.key] = entry1.value;
          }
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.harEntryJson = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Event_V1 {
    return {
      tags: isObject(object.tags)
        ? Object.entries(object.tags).reduce<{ [key: string]: string }>((acc, [key, value]) => {
          acc[key] = String(value);
          return acc;
        }, {})
        : {},
      harEntryJson: isSet(object.harEntryJson) ? bytesFromBase64(object.harEntryJson) : new Uint8Array(0),
    };
  },

  toJSON(message: Event_V1): unknown {
    const obj: any = {};
    if (message.tags) {
      const entries = Object.entries(message.tags);
      if (entries.length > 0) {
        obj.tags = {};
        entries.forEach(([k, v]) => {
          obj.tags[k] = v;
        });
      }
    }
    if (message.harEntryJson.length !== 0) {
      obj.harEntryJson = base64FromBytes(message.harEntryJson);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Event_V1>, I>>(base?: I): Event_V1 {
    return Event_V1.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Event_V1>, I>>(object: I): Event_V1 {
    const message = createBaseEvent_V1();
    message.tags = Object.entries(object.tags ?? {}).reduce<{ [key: string]: string }>((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = globalThis.String(value);
      }
      return acc;
    }, {});
    message.harEntryJson = object.harEntryJson ?? new Uint8Array(0);
    return message;
  },
};

function createBaseEvent_V1_TagsEntry(): Event_V1_TagsEntry {
  return { key: "", value: "" };
}

export const Event_V1_TagsEntry = {
  encode(message: Event_V1_TagsEntry, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key !== "") {
      writer.uint32(10).string(message.key);
    }
    if (message.value !== "") {
      writer.uint32(18).string(message.value);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Event_V1_TagsEntry {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseEvent_V1_TagsEntry();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.value = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Event_V1_TagsEntry {
    return {
      key: isSet(object.key) ? globalThis.String(object.key) : "",
      value: isSet(object.value) ? globalThis.String(object.value) : "",
    };
  },

  toJSON(message: Event_V1_TagsEntry): unknown {
    const obj: any = {};
    if (message.key !== "") {
      obj.key = message.key;
    }
    if (message.value !== "") {
      obj.value = message.value;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Event_V1_TagsEntry>, I>>(base?: I): Event_V1_TagsEntry {
    return Event_V1_TagsEntry.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Event_V1_TagsEntry>, I>>(object: I): Event_V1_TagsEntry {
    const message = createBaseEvent_V1_TagsEntry();
    message.key = object.key ?? "";
    message.value = object.value ?? "";
    return message;
  },
};

function createBaseSetSubscriberConfig(): SetSubscriberConfig {
  return { concreteV1: undefined };
}

export const SetSubscriberConfig = {
  encode(message: SetSubscriberConfig, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.concreteV1 !== undefined) {
      SetSubscriberConfig_V1.encode(message.concreteV1, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSubscriberConfig {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriberConfig();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.concreteV1 = SetSubscriberConfig_V1.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSubscriberConfig {
    return { concreteV1: isSet(object.concreteV1) ? SetSubscriberConfig_V1.fromJSON(object.concreteV1) : undefined };
  },

  toJSON(message: SetSubscriberConfig): unknown {
    const obj: any = {};
    if (message.concreteV1 !== undefined) {
      obj.concreteV1 = SetSubscriberConfig_V1.toJSON(message.concreteV1);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSubscriberConfig>, I>>(base?: I): SetSubscriberConfig {
    return SetSubscriberConfig.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSubscriberConfig>, I>>(object: I): SetSubscriberConfig {
    const message = createBaseSetSubscriberConfig();
    message.concreteV1 = (object.concreteV1 !== undefined && object.concreteV1 !== null)
      ? SetSubscriberConfig_V1.fromPartial(object.concreteV1)
      : undefined;
    return message;
  },
};

function createBaseSetSubscriberConfig_V1(): SetSubscriberConfig_V1 {
  return { call: undefined, result: undefined };
}

export const SetSubscriberConfig_V1 = {
  encode(message: SetSubscriberConfig_V1, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.call !== undefined) {
      SetSubscriberConfig_V1_Call.encode(message.call, writer.uint32(10).fork()).ldelim();
    }
    if (message.result !== undefined) {
      SetSubscriberConfig_V1_Result.encode(message.result, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSubscriberConfig_V1 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriberConfig_V1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.call = SetSubscriberConfig_V1_Call.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.result = SetSubscriberConfig_V1_Result.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSubscriberConfig_V1 {
    return {
      call: isSet(object.call) ? SetSubscriberConfig_V1_Call.fromJSON(object.call) : undefined,
      result: isSet(object.result) ? SetSubscriberConfig_V1_Result.fromJSON(object.result) : undefined,
    };
  },

  toJSON(message: SetSubscriberConfig_V1): unknown {
    const obj: any = {};
    if (message.call !== undefined) {
      obj.call = SetSubscriberConfig_V1_Call.toJSON(message.call);
    }
    if (message.result !== undefined) {
      obj.result = SetSubscriberConfig_V1_Result.toJSON(message.result);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSubscriberConfig_V1>, I>>(base?: I): SetSubscriberConfig_V1 {
    return SetSubscriberConfig_V1.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSubscriberConfig_V1>, I>>(object: I): SetSubscriberConfig_V1 {
    const message = createBaseSetSubscriberConfig_V1();
    message.call = (object.call !== undefined && object.call !== null)
      ? SetSubscriberConfig_V1_Call.fromPartial(object.call)
      : undefined;
    message.result = (object.result !== undefined && object.result !== null)
      ? SetSubscriberConfig_V1_Result.fromPartial(object.result)
      : undefined;
    return message;
  },
};

function createBaseSetSubscriberConfig_V1_Call(): SetSubscriberConfig_V1_Call {
  return { revision: 0n, filters: [] };
}

export const SetSubscriberConfig_V1_Call = {
  encode(message: SetSubscriberConfig_V1_Call, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.revision !== 0n) {
      if (BigInt.asUintN(64, message.revision) !== message.revision) {
        throw new globalThis.Error("value provided for field message.revision of type uint64 too large");
      }
      writer.uint32(8).uint64(message.revision.toString());
    }
    for (const v of message.filters) {
      writer.uint32(18).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSubscriberConfig_V1_Call {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriberConfig_V1_Call();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.revision = longToBigint(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.filters.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSubscriberConfig_V1_Call {
    return {
      revision: isSet(object.revision) ? BigInt(object.revision) : 0n,
      filters: globalThis.Array.isArray(object?.filters) ? object.filters.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: SetSubscriberConfig_V1_Call): unknown {
    const obj: any = {};
    if (message.revision !== 0n) {
      obj.revision = message.revision.toString();
    }
    if (message.filters?.length) {
      obj.filters = message.filters;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSubscriberConfig_V1_Call>, I>>(base?: I): SetSubscriberConfig_V1_Call {
    return SetSubscriberConfig_V1_Call.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSubscriberConfig_V1_Call>, I>>(object: I): SetSubscriberConfig_V1_Call {
    const message = createBaseSetSubscriberConfig_V1_Call();
    message.revision = object.revision ?? 0n;
    message.filters = object.filters?.map((e) => e) || [];
    return message;
  },
};

function createBaseSetSubscriberConfig_V1_Result(): SetSubscriberConfig_V1_Result {
  return { revision: 0n, error: undefined };
}

export const SetSubscriberConfig_V1_Result = {
  encode(message: SetSubscriberConfig_V1_Result, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.revision !== 0n) {
      if (BigInt.asUintN(64, message.revision) !== message.revision) {
        throw new globalThis.Error("value provided for field message.revision of type uint64 too large");
      }
      writer.uint32(8).uint64(message.revision.toString());
    }
    if (message.error !== undefined) {
      writer.uint32(18).string(message.error);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SetSubscriberConfig_V1_Result {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSetSubscriberConfig_V1_Result();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.revision = longToBigint(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): SetSubscriberConfig_V1_Result {
    return {
      revision: isSet(object.revision) ? BigInt(object.revision) : 0n,
      error: isSet(object.error) ? globalThis.String(object.error) : undefined,
    };
  },

  toJSON(message: SetSubscriberConfig_V1_Result): unknown {
    const obj: any = {};
    if (message.revision !== 0n) {
      obj.revision = message.revision.toString();
    }
    if (message.error !== undefined) {
      obj.error = message.error;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<SetSubscriberConfig_V1_Result>, I>>(base?: I): SetSubscriberConfig_V1_Result {
    return SetSubscriberConfig_V1_Result.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<SetSubscriberConfig_V1_Result>, I>>(
    object: I,
  ): SetSubscriberConfig_V1_Result {
    const message = createBaseSetSubscriberConfig_V1_Result();
    message.revision = object.revision ?? 0n;
    message.error = object.error ?? undefined;
    return message;
  },
};

function createBaseAnnounceStats(): AnnounceStats {
  return { concreteV1: undefined };
}

export const AnnounceStats = {
  encode(message: AnnounceStats, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.concreteV1 !== undefined) {
      AnnounceStats_V1.encode(message.concreteV1, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnounceStats {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnounceStats();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.concreteV1 = AnnounceStats_V1.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnnounceStats {
    return { concreteV1: isSet(object.concreteV1) ? AnnounceStats_V1.fromJSON(object.concreteV1) : undefined };
  },

  toJSON(message: AnnounceStats): unknown {
    const obj: any = {};
    if (message.concreteV1 !== undefined) {
      obj.concreteV1 = AnnounceStats_V1.toJSON(message.concreteV1);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnnounceStats>, I>>(base?: I): AnnounceStats {
    return AnnounceStats.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnnounceStats>, I>>(object: I): AnnounceStats {
    const message = createBaseAnnounceStats();
    message.concreteV1 = (object.concreteV1 !== undefined && object.concreteV1 !== null)
      ? AnnounceStats_V1.fromPartial(object.concreteV1)
      : undefined;
    return message;
  },
};

function createBaseAnnounceStats_V1(): AnnounceStats_V1 {
  return { numPublishers: 0n, numSubscribers: 0n };
}

export const AnnounceStats_V1 = {
  encode(message: AnnounceStats_V1, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.numPublishers !== 0n) {
      if (BigInt.asUintN(64, message.numPublishers) !== message.numPublishers) {
        throw new globalThis.Error("value provided for field message.numPublishers of type uint64 too large");
      }
      writer.uint32(8).uint64(message.numPublishers.toString());
    }
    if (message.numSubscribers !== 0n) {
      if (BigInt.asUintN(64, message.numSubscribers) !== message.numSubscribers) {
        throw new globalThis.Error("value provided for field message.numSubscribers of type uint64 too large");
      }
      writer.uint32(16).uint64(message.numSubscribers.toString());
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AnnounceStats_V1 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAnnounceStats_V1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.numPublishers = longToBigint(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numSubscribers = longToBigint(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): AnnounceStats_V1 {
    return {
      numPublishers: isSet(object.numPublishers) ? BigInt(object.numPublishers) : 0n,
      numSubscribers: isSet(object.numSubscribers) ? BigInt(object.numSubscribers) : 0n,
    };
  },

  toJSON(message: AnnounceStats_V1): unknown {
    const obj: any = {};
    if (message.numPublishers !== 0n) {
      obj.numPublishers = message.numPublishers.toString();
    }
    if (message.numSubscribers !== 0n) {
      obj.numSubscribers = message.numSubscribers.toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<AnnounceStats_V1>, I>>(base?: I): AnnounceStats_V1 {
    return AnnounceStats_V1.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<AnnounceStats_V1>, I>>(object: I): AnnounceStats_V1 {
    const message = createBaseAnnounceStats_V1();
    message.numPublishers = object.numPublishers ?? 0n;
    message.numSubscribers = object.numSubscribers ?? 0n;
    return message;
  },
};

function createBaseMessage(): Message {
  return { concreteV1: undefined };
}

export const Message = {
  encode(message: Message, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.concreteV1 !== undefined) {
      Message_V1.encode(message.concreteV1, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.concreteV1 = Message_V1.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message {
    return { concreteV1: isSet(object.concreteV1) ? Message_V1.fromJSON(object.concreteV1) : undefined };
  },

  toJSON(message: Message): unknown {
    const obj: any = {};
    if (message.concreteV1 !== undefined) {
      obj.concreteV1 = Message_V1.toJSON(message.concreteV1);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message>, I>>(base?: I): Message {
    return Message.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message>, I>>(object: I): Message {
    const message = createBaseMessage();
    message.concreteV1 = (object.concreteV1 !== undefined && object.concreteV1 !== null)
      ? Message_V1.fromPartial(object.concreteV1)
      : undefined;
    return message;
  },
};

function createBaseMessage_V1(): Message_V1 {
  return { event: undefined, setSubscriberConfig: undefined, announceStats: undefined };
}

export const Message_V1 = {
  encode(message: Message_V1, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.event !== undefined) {
      Event.encode(message.event, writer.uint32(10).fork()).ldelim();
    }
    if (message.setSubscriberConfig !== undefined) {
      SetSubscriberConfig.encode(message.setSubscriberConfig, writer.uint32(18).fork()).ldelim();
    }
    if (message.announceStats !== undefined) {
      AnnounceStats.encode(message.announceStats, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Message_V1 {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMessage_V1();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.event = Event.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.setSubscriberConfig = SetSubscriberConfig.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.announceStats = AnnounceStats.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Message_V1 {
    return {
      event: isSet(object.event) ? Event.fromJSON(object.event) : undefined,
      setSubscriberConfig: isSet(object.setSubscriberConfig)
        ? SetSubscriberConfig.fromJSON(object.setSubscriberConfig)
        : undefined,
      announceStats: isSet(object.announceStats) ? AnnounceStats.fromJSON(object.announceStats) : undefined,
    };
  },

  toJSON(message: Message_V1): unknown {
    const obj: any = {};
    if (message.event !== undefined) {
      obj.event = Event.toJSON(message.event);
    }
    if (message.setSubscriberConfig !== undefined) {
      obj.setSubscriberConfig = SetSubscriberConfig.toJSON(message.setSubscriberConfig);
    }
    if (message.announceStats !== undefined) {
      obj.announceStats = AnnounceStats.toJSON(message.announceStats);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Message_V1>, I>>(base?: I): Message_V1 {
    return Message_V1.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Message_V1>, I>>(object: I): Message_V1 {
    const message = createBaseMessage_V1();
    message.event = (object.event !== undefined && object.event !== null) ? Event.fromPartial(object.event) : undefined;
    message.setSubscriberConfig = (object.setSubscriberConfig !== undefined && object.setSubscriberConfig !== null)
      ? SetSubscriberConfig.fromPartial(object.setSubscriberConfig)
      : undefined;
    message.announceStats = (object.announceStats !== undefined && object.announceStats !== null)
      ? AnnounceStats.fromPartial(object.announceStats)
      : undefined;
    return message;
  },
};

export interface DataLoaderOptions {
  cache?: boolean;
}

export interface DataLoaders {
  rpcDataLoaderOptions?: DataLoaderOptions;
  getDataLoader<T>(identifier: string, constructorFn: () => T): T;
}

type ProtoMetaMessageOptions = {
  options?: { [key: string]: any };
  fields?: { [key: string]: { [key: string]: any } };
  oneof?: { [key: string]: { [key: string]: any } };
  nested?: { [key: string]: ProtoMetaMessageOptions };
};

export interface ProtoMetadata {
  fileDescriptor: FileDescriptorProto;
  references: { [key: string]: any };
  dependencies?: ProtoMetadata[];
  options?: {
    options?: { [key: string]: any };
    services?: {
      [key: string]: { options?: { [key: string]: any }; methods?: { [key: string]: { [key: string]: any } } };
    };
    messages?: { [key: string]: ProtoMetaMessageOptions };
    enums?: { [key: string]: { options?: { [key: string]: any }; values?: { [key: string]: { [key: string]: any } } } };
  };
}

export const protoMetadata: ProtoMetadata = {
  fileDescriptor: FileDescriptorProto.fromPartial({
    "name": "subtrace/pubsub/pubsub.proto",
    "package": "subtrace.pubsub",
    "dependency": [],
    "publicDependency": [],
    "weakDependency": [],
    "messageType": [{
      "name": "JoinPublisher",
      "field": [],
      "extension": [],
      "nestedType": [{
        "name": "Request",
        "field": [],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }, {
        "name": "Response",
        "field": [{
          "name": "error",
          "number": 1000,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "error",
          "options": undefined,
          "proto3Optional": true,
        }, {
          "name": "websocket_url",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "websocketUrl",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "_error", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "JoinSubscriber",
      "field": [],
      "extension": [],
      "nestedType": [{
        "name": "Request",
        "field": [{
          "name": "namespace_id",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "namespaceId",
          "options": undefined,
          "proto3Optional": true,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "_namespace_id", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }, {
        "name": "Response",
        "field": [{
          "name": "error",
          "number": 1000,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "error",
          "options": undefined,
          "proto3Optional": true,
        }, {
          "name": "websocket_url",
          "number": 1,
          "label": 1,
          "type": 9,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "websocketUrl",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "_error", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Event",
      "field": [{
        "name": "concrete_v1",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".subtrace.pubsub.Event.V1",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "concreteV1",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [{
        "name": "V1",
        "field": [{
          "name": "tags",
          "number": 1,
          "label": 3,
          "type": 11,
          "typeName": ".subtrace.pubsub.Event.V1.TagsEntry",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "tags",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "har_entry_json",
          "number": 2,
          "label": 1,
          "type": 12,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "harEntryJson",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [{
          "name": "TagsEntry",
          "field": [{
            "name": "key",
            "number": 1,
            "label": 1,
            "type": 9,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "key",
            "options": undefined,
            "proto3Optional": false,
          }, {
            "name": "value",
            "number": 2,
            "label": 1,
            "type": 9,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "value",
            "options": undefined,
            "proto3Optional": false,
          }],
          "extension": [],
          "nestedType": [],
          "enumType": [],
          "extensionRange": [],
          "oneofDecl": [],
          "options": {
            "messageSetWireFormat": false,
            "noStandardDescriptorAccessor": false,
            "deprecated": false,
            "mapEntry": true,
            "uninterpretedOption": [],
          },
          "reservedRange": [],
          "reservedName": [],
        }],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "concrete", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "SetSubscriberConfig",
      "field": [{
        "name": "concrete_v1",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".subtrace.pubsub.SetSubscriberConfig.V1",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "concreteV1",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [{
        "name": "V1",
        "field": [{
          "name": "call",
          "number": 1,
          "label": 1,
          "type": 11,
          "typeName": ".subtrace.pubsub.SetSubscriberConfig.V1.Call",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "call",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "result",
          "number": 2,
          "label": 1,
          "type": 11,
          "typeName": ".subtrace.pubsub.SetSubscriberConfig.V1.Result",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "result",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [{
          "name": "Call",
          "field": [{
            "name": "revision",
            "number": 1,
            "label": 1,
            "type": 4,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "revision",
            "options": undefined,
            "proto3Optional": false,
          }, {
            "name": "filters",
            "number": 2,
            "label": 3,
            "type": 9,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "filters",
            "options": undefined,
            "proto3Optional": false,
          }],
          "extension": [],
          "nestedType": [],
          "enumType": [],
          "extensionRange": [],
          "oneofDecl": [],
          "options": undefined,
          "reservedRange": [],
          "reservedName": [],
        }, {
          "name": "Result",
          "field": [{
            "name": "revision",
            "number": 1,
            "label": 1,
            "type": 4,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "revision",
            "options": undefined,
            "proto3Optional": false,
          }, {
            "name": "error",
            "number": 2,
            "label": 1,
            "type": 9,
            "typeName": "",
            "extendee": "",
            "defaultValue": "",
            "oneofIndex": 0,
            "jsonName": "error",
            "options": undefined,
            "proto3Optional": true,
          }],
          "extension": [],
          "nestedType": [],
          "enumType": [],
          "extensionRange": [],
          "oneofDecl": [{ "name": "_error", "options": undefined }],
          "options": undefined,
          "reservedRange": [],
          "reservedName": [],
        }],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "type", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "concrete", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "AnnounceStats",
      "field": [{
        "name": "concrete_v1",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".subtrace.pubsub.AnnounceStats.V1",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "concreteV1",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [{
        "name": "V1",
        "field": [{
          "name": "num_publishers",
          "number": 1,
          "label": 1,
          "type": 4,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "numPublishers",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "num_subscribers",
          "number": 2,
          "label": 1,
          "type": 4,
          "typeName": "",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "numSubscribers",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "concrete", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }, {
      "name": "Message",
      "field": [{
        "name": "concrete_v1",
        "number": 1,
        "label": 1,
        "type": 11,
        "typeName": ".subtrace.pubsub.Message.V1",
        "extendee": "",
        "defaultValue": "",
        "oneofIndex": 0,
        "jsonName": "concreteV1",
        "options": undefined,
        "proto3Optional": false,
      }],
      "extension": [],
      "nestedType": [{
        "name": "V1",
        "field": [{
          "name": "event",
          "number": 1,
          "label": 1,
          "type": 11,
          "typeName": ".subtrace.pubsub.Event",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "event",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "set_subscriber_config",
          "number": 2,
          "label": 1,
          "type": 11,
          "typeName": ".subtrace.pubsub.SetSubscriberConfig",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "setSubscriberConfig",
          "options": undefined,
          "proto3Optional": false,
        }, {
          "name": "announce_stats",
          "number": 3,
          "label": 1,
          "type": 11,
          "typeName": ".subtrace.pubsub.AnnounceStats",
          "extendee": "",
          "defaultValue": "",
          "oneofIndex": 0,
          "jsonName": "announceStats",
          "options": undefined,
          "proto3Optional": false,
        }],
        "extension": [],
        "nestedType": [],
        "enumType": [],
        "extensionRange": [],
        "oneofDecl": [{ "name": "underlying", "options": undefined }],
        "options": undefined,
        "reservedRange": [],
        "reservedName": [],
      }],
      "enumType": [],
      "extensionRange": [],
      "oneofDecl": [{ "name": "concrete", "options": undefined }],
      "options": undefined,
      "reservedRange": [],
      "reservedName": [],
    }],
    "enumType": [],
    "service": [],
    "extension": [],
    "options": {
      "javaPackage": "",
      "javaOuterClassname": "",
      "javaMultipleFiles": false,
      "javaGenerateEqualsAndHash": false,
      "javaStringCheckUtf8": false,
      "optimizeFor": 1,
      "goPackage": "subtrace.dev/pubsub",
      "ccGenericServices": false,
      "javaGenericServices": false,
      "pyGenericServices": false,
      "phpGenericServices": false,
      "deprecated": false,
      "ccEnableArenas": true,
      "objcClassPrefix": "",
      "csharpNamespace": "",
      "swiftPrefix": "",
      "phpClassPrefix": "",
      "phpNamespace": "",
      "phpMetadataNamespace": "",
      "rubyPackage": "",
      "uninterpretedOption": [],
    },
    "sourceCodeInfo": {
      "location": [{
        "path": [4, 0],
        "span": [7, 0, 15, 1],
        "leadingComments": " POST /api/JoinPublisher\n",
        "trailingComments": "",
        "leadingDetachedComments": [],
      }, {
        "path": [4, 1],
        "span": [18, 0, 27, 1],
        "leadingComments": " POST /api/JoinSubscriber\n",
        "trailingComments": "",
        "leadingDetachedComments": [],
      }],
    },
    "syntax": "proto3",
  }),
  references: {
    ".subtrace.pubsub.JoinPublisher": JoinPublisher,
    ".subtrace.pubsub.JoinPublisher.Request": JoinPublisher_Request,
    ".subtrace.pubsub.JoinPublisher.Response": JoinPublisher_Response,
    ".subtrace.pubsub.JoinSubscriber": JoinSubscriber,
    ".subtrace.pubsub.JoinSubscriber.Request": JoinSubscriber_Request,
    ".subtrace.pubsub.JoinSubscriber.Response": JoinSubscriber_Response,
    ".subtrace.pubsub.Event": Event,
    ".subtrace.pubsub.Event.V1": Event_V1,
    ".subtrace.pubsub.Event.V1.TagsEntry": Event_V1_TagsEntry,
    ".subtrace.pubsub.SetSubscriberConfig": SetSubscriberConfig,
    ".subtrace.pubsub.SetSubscriberConfig.V1": SetSubscriberConfig_V1,
    ".subtrace.pubsub.SetSubscriberConfig.V1.Call": SetSubscriberConfig_V1_Call,
    ".subtrace.pubsub.SetSubscriberConfig.V1.Result": SetSubscriberConfig_V1_Result,
    ".subtrace.pubsub.AnnounceStats": AnnounceStats,
    ".subtrace.pubsub.AnnounceStats.V1": AnnounceStats_V1,
    ".subtrace.pubsub.Message": Message,
    ".subtrace.pubsub.Message.V1": Message_V1,
  },
  dependencies: [],
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToBigint(long: Long) {
  return BigInt(long.toString());
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isObject(value: any): boolean {
  return typeof value === "object" && value !== null;
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
